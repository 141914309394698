<template>
  <div class="">
    <div class="body">
      <div class="container">
        <h1>关于我们</h1>
        <article>
          一、国学台概况： <br/>
          国学台全称：中国教育网络电视台国学频道，是目前唯一国家级网络电视台开办的国学频道。
          网址： www.guoxue.centv.cn。 中国教育网络电视台CENTV，是中国教育电视台CETV的官网。 
          国学台在2016年被国家网信办评为“全国十大国学教育平台之一”。自2015年创立以来，已取得良好的社会效益，其
          中“国学春晚”、“国学新六艺大赛”、“全国教育局长、校长国学论坛”等活动，得到专业领域及社会的广泛好评。
          其中“国学春晚”点击量过千万。<br/><br/>

          二、国学台优势： <br/>
          权威、严肃、公正、学术性的国家级媒体，内容可被其他媒体信任转发。 中国教育电视台是国家教育部主管的国家级专
          业电视台，与中国中央电视台并称两大国家级电视台，致力成为全球最大的教育平台。面向全国40万所中小学，覆盖2
          亿中小学生。在2020年疫情期间，承担国家教育部的国家级官方网课教育平台任务，最多时同时在线5000万人，影响
          3.8亿人。 自2015年11月办台至今，已经积累了10000部以上的教学视频，与全国200名以上文化名家、优秀教师，
          以及500名以上教育局长校长保持良好的沟通。 <br/><br/>

          三、时代使命： <br/>
          世界正面临百年未有之大变局，中国也正值伟大复兴的最佳历史时期。 在非常的历史转折期，针对青年和青少年价值观
          的重树，恢复文化自信，迎接来自未来的挑战，培养德智体美劳全面发展的社会主义事业合格接班人，打造能够担当民
          族复兴大业的时代新人，显得尤为重要和迫切！ <br/><br/>

          四、办台特色： <br/>
          “树榜样、正视听”“激浊扬清、删繁就简”“创新办台、开放办台”，打造“融媒体+在线教育+文化俱乐部”的新型
          传统文化商业生态链。 以央媒为敲门砖、纽带和阵地，凝聚文化名人，从而建立国学爱好者社群，形成包括学习、生
          活、工作各领域的互信互助平台。 <br/><br/>

五、愿景 :  打造全球最大的中华优秀传统文化学习平台<br/>
        </article>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
// import { logout, modifyPassword } from "@api"
// import VideoItem from '@c/VideoItem.vue'
export default {
  name: "about",
};
</script>


<style scoped lang="stylus">
.body {
  height: 100%;
  // background: #fff;
}

.container {
  padding: 20px 0;
}

h1 {
  text-align: center;
  font-size 35px
  color: #52504D;    
  font-family: '微软雅黑';
  
}
article {
  font-size 17px
  color: #52504D;
  font-family: '微软雅黑'
  line-height 32px
  margin-bottom 90px;
}
</style>
